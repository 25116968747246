import axios from 'axios'
import store from 'store'
import { notification } from 'antd'

const url =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:8484/api'
    : 'https://js-v2manager-main.herokuapp.com/api'
// console.log(url)
const apiClient = axios.create({
  baseURL: url,
  // timeout: 1000,
  // headers: { 'X-Custom-Header': 'foobar' }
})

apiClient.interceptors.request.use(request => {
  const accessToken = store.get('accessToken')
  if (accessToken) {
    request.headers.Authorization = `Bearer ${accessToken}`
    request.headers.AccessToken = accessToken
  }
  return request
})

apiClient.interceptors.response.use(undefined, error => {
  // Errors handling
  const { response } = error
  const { data } = response
  console.log(data)
  if (data) {
    notification.warning({
      message: data.message,
    })
  }
})

export default apiClient
